<template>
  <div>
    <v-app class="desktopLayoutClass">
      <div>
        <v-row justify="center">
          <v-dialog v-model="auth_error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                {{$t("Warning_Message.Session_Expired_Please_Login_Again")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  depressed
                  @click="closeDialogUnauthorized"
                >
                   {{$t("Warning_Message.Login")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="error_enable" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                 {{$t("Warning_Message.Something_Went_Wrong")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="closeDialogError">
                   {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="KBZEmployee" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
                {{ $t("Customer.AddLinkedContact.KBZ_Employees_Cannot_Be_Added_As_Linked_Contacts") }}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="closeKBZEmployeeError">
                   {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div>
        <v-row justify="center">
          <v-dialog v-model="already_exists" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
               {{$t("Warning_Message.This_Linked_Contact_Already_Exists")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="closeAlreadyExists">
                   {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div>
        <v-row justify="center">
          <v-dialog v-model="same_number" persistent max-width="290">
            <v-card>
              <v-card-title style="word-break: normal">
               Cannot create a linked contact with your number.
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed color="primary" @click="closeSameNumber">
                   {{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
			<div>
				<v-row justify="center">
					<v-dialog v-model="ageLimitModal" persistent max-width="290">
					<v-card>
						<v-card-title style="word-break:normal">
						 {{$t("Warning_Message.Invalid_Linked_Contact_Please_Add_Dependent_Through_My_Dependents")}}
						</v-card-title>
						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" depressed @click="closeageLimitModal">
							 {{$t("Warning_Message.Ok")}}
						</v-btn>
						</v-card-actions>
					</v-card>
					</v-dialog>
				</v-row>
			</div>

      <div>
				<v-row justify="center">
					<v-dialog v-model="AddedLinkedContact" persistent max-width="290">
					<v-card>
						<v-card-title style="word-break:normal">
						   {{$t("Warning_Message.Linked_Contact_Added_Successfully")}}
						</v-card-title>
						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" depressed @click="closeAddedLinkedContact">
						 {{$t("Warning_Message.Ok")}}
						</v-btn>
						</v-card-actions>
					</v-card>
					</v-dialog>
				</v-row>
			</div>

			<div>
				<v-row justify="center">
					<v-dialog v-model="error_enable" persistent max-width="290">
					<v-card>
						<v-card-title style="word-break:normal">
						{{$t("Warning_Message.Something_Went_Wrong")}}
						</v-card-title>
						<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn depressed color="primary" @click="closeDialogError">
						{{$t("Warning_Message.Ok")}}
						</v-btn>
						</v-card-actions>
					</v-card>
					</v-dialog>
				</v-row>
			</div>

      <div>
        <v-row justify="center">
          <v-dialog
            v-model="required_fields_error_enable"
            persistent
            max-width="290"
          >
            <v-card>
              <v-card-title style="word-break: normal">
                	{{$t("Warning_Message.Please_Fill_All_Details")}}
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  depressed
                  color="primary"
                  @click="closeRequiredDialogError"
                >
                 	{{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>

      <div class="mainDivaddDependent">
      <div
        v-show="show_header"
        class="header"
        style="
          display: flex;
          flex-direction: row;
          padding-top: 10px;
          padding-left: 5px;
        "
        >
        <div style="display: flex; flex-direction: row; width: 100%">
          <div
            style="width: 10%; padding-top: 6px"
            align="left"
            v-on:click="redirectToMyLinkedContacts"
          >
            <img
              src="https://img.icons8.com/material-outlined/20/000000/left.png"
            />
          </div>
          <div style="width: 90%" align="left">
            <span style="font-weight: 600; font-size: 15pt">{{ $t("Customer.AddLinkedContact.Add_Linked_Contact") }}</span>
          </div>
        </div>
      </div>
      <div>
        <v-row justify="center">
          <v-dialog v-model="editProfileSuccessModal" max-width="290">
            <v-card>
              <v-card-title style="word-break: normal; margin-top: 20px">
                	{{$t("Warning_Message.Linked_Contact_Edited")}}
              </v-card-title>
              <v-card-text></v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  align="center"
                  color="primary"
                  depressed
                  @click="closeEditProfileSuccessModal"
                >
                 	{{$t("Warning_Message.Ok")}}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </div>
      <div
      class="PageClass"
        style="
          padding: 10px 20px !important;
          background-color: #f5faff;
          margin-top:25px;
        "
      >
        <p style="font-size:85%">{{ $t("Customer.AddLinkedContact.Validity") }}</p>
        <v-row>
          <p
            style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 7px;font-weight: 600 !important;
            "
          >
            {{ $t("Customer.AddLinkedContact.Relationship") }}
          </p>
          <v-select
          v-if="$i18n.locale=='en'"
          :items="Relationship_list"
          v-model="dependent_relationship"
          placeholder="Relationship"
          style="width: 100%; border-radius: 10px; margin-right: 10px; margin-left: 10px; margin-top: 10px;"
          outlined
          item-text = "en_text"
          return-object
          background-color="#F4F6FA"
          :rules="relationshipRules"
        >
        </v-select>
        <v-select
          v-if="$i18n.locale=='mm'"
          :items="Relationship_list"
          v-model="dependent_relationship"
          placeholder="တော်စပ်မှု"
          style="width: 100%; border-radius: 10px; margin-right: 10px; margin-left: 10px; margin-top: 10px;"
          outlined
          item-text = "mm_text"
          return-object
          background-color="#F4F6FA"
          :rules="relationshipRulesMM"
        >
        </v-select>
        </v-row>
        <v-row>
          <p
            style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 7px;font-weight: 600 !important;">
            {{ $t("Customer.AddLinkedContact.Name") }}
          </p>
           </v-row>
           <v-row>
          <v-text-field
            v-model="dependent_name"
            outlined
            :placeholder="translation_enter_name"
            :rules="$store.state.locale == 'en' ? nameRules : nameRulesMM"
            style=" border-radius: 10px; margin-right: 10px; margin-left: 10px; margin-top: 10px;"
            background-color="#F4F6FA"
          >
          </v-text-field>
       </v-row>
        <v-row>
          <!-- <v-col cols="6"> -->
          <p
            style="text-align: left; padding-bottom: 0px; margin-bottom: 0px; margin-left: 10px; margin-top: 7px;font-weight: 600 !important;">
            {{ $t("Customer.AddLinkedContact.Phone_Number") }}
          </p>
          <v-text-field
            v-model="dependent_phoneNumber"
            outlined
            :placeholder="translation_enter_number"
            type="number"
            :rules="$store.state.locale == 'en' ? mobileNumberRules : mobileNumberRulesMM"
            background-color="#F4F6FA"
            style="
              width: 90%;
              border-radius: 10px;
              margin-right: 10px;
              margin-left: 10px;
              margin-top: 10px;
            "
          >
          </v-text-field>
        </v-row>
        <v-row>
          <p
            style="
              text-align: left;
              padding-bottom: 0px;
              margin-bottom: 0px;
              margin-left: 10px;
              margin-top: 7px;
              font-weight: 600 !important;
            "
          >
            {{ $t("Customer.AddLinkedContact.Date_Of_Birth") }}
          </p>
          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dependent_dob"
                :placeholder="translation_dob"
                background-color="#F4F6FA"
                :rules="$store.state.locale == 'en' ? dobRules : dobRulesMM"
                outlined
                v-bind="attrs"
                v-on="on"
                style="width: 90%; border-radius: 10px; margin-right: 10px; margin-left: 10px; margin-top: 10px;"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dependent_dob"
              :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
              min="1900-01-01"
              full-width
              @input="get_age(dependent_dob), menu= false"
              @change="menu = false"
              style="400px !important"
            ></v-date-picker>
          </v-menu>
        </v-row>
        <v-row>
          <p
            style="
              text-align: left;
              padding-bottom: 0px;
              margin-bottom: 0px;
              margin-left: 10px;
              margin-top: 7px;
              font-weight: 600 !important;
            "
          >
            {{ $t("Customer.AddLinkedContact.Gender") }}
          </p>
          <v-select
           v-if="$i18n.locale=='en'"
            :items="gender"
            v-model="dependent_gender"
            outlined
            placeholder="Gender"
            :rules="genderRules"
            background-color="#F4F6FA"
            item-text = "en_text"
            return-object
            style="
              width: 90%;
              border-radius: 10px;
              margin-right: 10px;
              margin-left: 10px;
              margin-top: 10px;
            "
          >
          </v-select>
           <v-select
             v-if="$i18n.locale=='mm'"
            :items="gender"
            v-model="dependent_gender"
            outlined
            placeholder="ကျား/မ"
            :rules="genderRulesMM"
            background-color="#F4F6FA"
            item-text = "mm_text"
            return-object
            style="
              width: 90%;
              border-radius: 10px;
              margin-right: 10px;
              margin-left: 10px;
              margin-top: 10px;
            "
          >
          </v-select>
          <!-- </v-col> -->
          <!-- <v-col cols="6"> -->
        </v-row>
        <v-row class="ButtonClass">
          <v-btn
          color="primary"
          style="
            width: 95%;
            height: 48px;
            margin-left: 10px;
            margin-top: 10px;
            margin-bottom: 10px;
            align: center;
          "
            @click="saveDependentInfo"
            :disabled="isSaved ||
              !(
                dependent_relationship != '' &&
                dependent_name.trim() != '' &&
                dependent_gender != '' &&
                dependent_dob != '' &&
                dependent_phoneNumber != '' && dependent_phoneNumber.length >=7 && dependent_phoneNumber.length <=11
              ) || ageLimitNotInRange
            "
          >
            {{ $t("Customer.AddLinkedContact.Save") }}
          </v-btn>
        </v-row>
      </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import NavBar from "../../../views/navbar.vue";
import axios from "axios";
import bottomnavbar from "./bottomnavbar.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  name: "EditProfile",
  data() {
    return {
      dependent_name: "",
      AddedLinkedContact: false,
      dependent_phoneNumber: "",
      dependent_height: "",
      dependent_email: "",
      dependent_dob: "",
      menu:"",
      dependent_weight: "",
      dependent_height: "",
      dependent_gender: "",
      dependent_smoker: "",
      dependent_alcoholic: "",
      ageLimitModal : false,
      ageLimitNotInRange : false,
      dependent_nrc: "",
      submit_button: "",
      gender:[{"key":"male","mm_text":"ကျား","en_text":"Male"},{"key":"female","mm_text":"မ","en_text":"Female"},{"key":"other","mm_text":"အခြား","en_text":"Other"}],
      Relationship_list: [{"key":"parent","mm_text":"မိဘ","en_text":"Parent"},{"key":"child","mm_text":"သားသမီး","en_text":"Child"},{"key":"spouse","mm_text":"အိမ်ထောင်ဖက်","en_text":"Spouse"},{"key":"sibling","mm_text":"မောင်နှမ","en_text":"Sibling"}],
      dependent_relationship: "",
      customer_id: "",
      customer_name: "",
      mobileNumber: "",
      currentCustomer: "",
      auth_error_enable: false,
      error_enable: false,
      KBZEmployee: false,
      same_number: false,
      editProfileSuccessModal: false,
      height_weight_error_enable: false,
      required_fields_error_enable: false,
      smokerValueList: [{"key":"yes","mm_text":"သောက်သည်","en_text":"Yes"},{"key":"no","mm_text":"မသောက်ပါ","en_text":"No"}],
      alcoholicValueList: [{"key":"non_drinker","mm_text":"အရက်လုံးဝမသောက်ပါ","en_text":"Non drinker"},{"key":"social_drinker","mm_text":"လူမှုရေးအရသောက်ပါသည်","en_text":"Social drinker"},{"key":"moderate_drinker","mm_text":"အလွန်အကြူးမသောက်ပါ","en_text":"Moderate drinker"},{"key":"alcoholic","mm_text":"အရက်စွဲနေပါသည်","en_text":"Alcoholic"}],
      relationshipRules: [(v) => !!v || "Relationship is required"],
      relationshipRulesMM: [(v) => !!v || "တော်စပ်ပုံထည့်သွင်းရန် လိုအပ်ပါသည်။"],
      nameRules: [(v) => !!v || "Name is required"],
      nameRulesMM: [(v) => !!v || "အမည်ထည့်သွင်းရန် လိုအပ်ပါသည်။"],
      nrcRules: [(v) => !!v || "NRC is required"],
      genderRules: [(v) => !!v || "Gender is required"],
      genderRulesMM: [(v) => !!v || "ကျား/မထည့်သွင်းရန် လိုအပ်ပါသည်။"],
      weightRules: [(v) => !!v || "Weight is required"],
      emailRules: [(v) => !!v || "Email is required"],
      dobRules: [(v) => !!v || "DOB is required"],
      dobRulesMM : [(v) => !!v || "မွေးနေ့ထည့်သွင်းရန် လိုအပ်ပါသည်။"],
      heightRules: [
        (v) => !!v || "Height is required",
        (v) => (v && v.length <= 3) || "Height cannot be more than 1000 cms",
      ],
      weightRules: [
        (v) => !!v || "Weight is required",
        (v) => (v && v.length <= 3) || "Weight cannot be more than 1000 kgs",
      ],
      weightRulesMM: [
        (v) => !!v || "Weight is required",
        (v) => (v && v.length <= 3) || "အလေးချိန်သည် ပေါင် ၁၀၀၀ ထပ်ပိုများသောပမာဏ ဖြည့်သွင်း၍မရပါ။",
      ],
      mobileNumberRules: [
        (v) => !!v || "Mobile Number is required",
        (v) => (v && v.length >=7 && v.length <= 11) || 'Mobile Number should be between 7 to 11 numbers',
        (v) => v > 0 || "Mobile number cannot be negative",
      ],
      mobileNumberRulesMM: [
        (v) => !!v || "ဖုန်းနံပါတ်ထည့်သွင်းရန် လိုအပ်ပါသည်။",
        v => (v && v.length >=7 && v.length <= 11) || 'ဖုန်းနံပါတ်၏ ဂဏန်းအရေအတွက်မှာ ၇ လုံးမှ ၁၁ လုံးဖြစ်ရပါမည်။',
        v => v > 0 || 'ဖုန်းနံပါတ်တွင် အနုတ် (-) ထည့်၍ မရပါ',
      ],
      isMobile: false,
      isSaved: false,
      already_exists: false,
      translation_enter_name: "Enter Name",
      translation_dob: "Date Of Birth",
      translation_enter_number: "Enter Phone Number",
      show_header: true,
    };
  },
  methods: {
    closeageLimitModal(){
      this.ageLimitModal =false;
    },
    closeAddedLinkedContact(){
      this.AddedLinkedContact= false;
      this.$router.push({
        name: "MyLinkedContacts",
      });
    },
	get_age(dateString) {
      var today = new Date();
      var birthDate = new Date(dateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      if(age <18 || age >60){
         this.ageLimitNotInRange = true;
         this.ageLimitModal = true;
      }
	  else{
		  this.ageLimitNotInRange = false;
	  }
    },
    closeDialogUnauthorized() {
      this.auth_error_enable = false;
      this.$router.push({
        name: "MyLinkedContacts",
      });
    },
    closeHeightWeightDialogError() {
      this.height_weight_error_enable = false;
    },
    closeRequiredDialogError() {
      this.required_fields_error_enable = false;
    },
    closeDialogError() {
      this.error_enable = false;
    },
    closeKBZEmployeeError() {
      this.KBZEmployee = false;
    },
    closeAlreadyExists() {
      this.already_exists = false;
    },
    closeSameNumber() {
      this.same_number = false;
    },
    redirectToMyLinkedContacts()
    {
      this.$router.push({
        name: 'MyLinkedContacts'
      });
    },
    closeEditProfileSuccessModal() {
      this.editProfileSuccessModal = false;
    },
    saveDependentInfo() {
      if(this.dependent_phoneNumber != this.current_user_number)
      {
        this.isSaved = true;
        var saveDependentBody = {
           token: this.currentCustomer,
           typeOfUser: "CUSTOMER",
           linkedContactName : this.dependent_name,
           relationshipWithCustomer : this.dependent_relationship.en_text,
           linkedContactDob : this.dependent_dob ,
           gender :this.dependent_gender.en_text ,
           phoneNumber : this.dependent_phoneNumber,
        };
        axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/createLinkedContact",
          saveDependentBody
        )
        .then((saveDependentResponse) => {
            this.AddedLinkedContact = true;
        })
        .catch((saveDependentError) => {
          if (saveDependentError.response.status == 401) {
            this.auth_error_enable = true;
          }
          else if(saveDependentError.response.status == 305) {
            this.already_exists = true;
            this.isSaved = false;
          }
          else if(saveDependentError.response.status == 408) {
            this.KBZEmployee = true;
            this.isSaved = false;
          }
          else
          {
            this.error_enable = true;
            this.isSaved = false;
          }
        });
      }
      else
      {
        this.same_number = true;
      }
    },
  },
  mounted() {
    var userAgent = navigator.userAgent
    if(userAgent.includes("kbzpay")){
      this.show_header = false;
    }
    this.isMobile = this.$device.mobile;
    if(this.$store.state.locale == "en")
      document.title = "Add Linked Contact"
    else
      document.title = "အခြားသုံးစွဲသူနှင့်ချိတ်ဆက်ခြင်း"
    this.currentCustomer = this.$cookies.get("customerToken");
    this.$i18n.locale = this.$store.state.locale;
    if(this.$i18n.locale =="mm")
    {
      this.translation_enter_name = "အမည်ထည့်မည်";
      this.translation_dob = "မွေးနေ့";
      this.translation_enter_number = "ဖုန်းနံပါတ်";
    }
    if (!this.currentCustomer) {
      this.$router.push({
        name: "Login",
      });
    } else {
      var verifyCustomerTokenData = {
        token: this.currentCustomer,
        typeOfUser: "CUSTOMER",
      };
      axios
        .post(
          process.env.VUE_APP_BACKEND_URL + "/verifyCustomerToken",
          verifyCustomerTokenData
        )
        .then((verifyCustomerTokenDataResponse) => {
          this.customer_name = verifyCustomerTokenDataResponse.data.data.customer_name;
          this.current_user_number = verifyCustomerTokenDataResponse.data.data.phoneNumber;
        })
        .catch((verifyCustomerTokenDataError) => {
          if (verifyCustomerTokenDataError.response.status == 401) {
            this.auth_error_enable = true;
          } else {
            this.error_enable = true;
          }
        });
    }
  },
};
</script>

<style scoped>
.header {
  display: block;
  box-shadow: 0px 2px 15px 0px #00000026;
  padding: 16px 20px !important;
  background-color: white;
}

.font_size_12_400 {
  font-size: 14px;
  font-weight: 400;
}
.mx-input {
  height: 40px !important;
  border-bottom: 1px solid #ccc !important;
  border-radius: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
/* .v-picker--full-width {
  height: 500px;
} */
/* @media (min-width: 800px){
  .mainDivaddDependent{
   width: 800px !important;
   margin-left: auto !important ;
    margin-right: auto !important ;
  }
  .PageClass{
     margin-top: 25px !important;
  }
  } */
</style>
